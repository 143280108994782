import type { AuthModalOverrides } from 'src/types';
import classnames from 'classnames';
import styles from './OverrideModal.module.less';
import { Text } from '@nerdwallet/currency';
import { Button } from '@nerdwallet/currency';
import CloseButton from './auth/buttons/CloseButton';
import { colors } from '@nerdwallet/base-styles/variables';
import { useState } from 'preact/hooks';
import { addTrkParams } from './lib/tracking';
import SegmentImpression, {
  ImpressionType,
} from './lib/segment/SegmentImpression';
import * as uuid from 'uuid';
import useAnalytics from './lib/segment/useAnalytics';
import _ from 'lodash';
interface Props {
  authModalOverride: AuthModalOverrides;
}

const OverrideModal: React.FC<Props> = ({ authModalOverride }) => {
  if (!authModalOverride || _.isEmpty(authModalOverride)) return null;

  const segment = useAnalytics();
  const [segmentImpressionId] = useState(uuid.v4());

  const [showOverrideModal, setShowOverrideModal] = useState(true);
  const closeModal = () => {
    setShowOverrideModal(false);
  };

  const handleClick = (segmentImpressionId) => {
    const eventProps = {
      segmentAttributes: {
        entity_name: 'global_nav_flexible_modal_cta',
        parent_event_id: segmentImpressionId,
      },
    };
    segment.track('link_clicked', eventProps);
  };

  const checkBackgroundColor = (backgroundColor) => {
    if (colors[backgroundColor]) {
      return colors[backgroundColor];
    }
    return colors['blue-lightest'];
  };

  const isNotEmpty = (authModalOverride) => {
    return (
      authModalOverride?.title ||
      authModalOverride?.body ||
      authModalOverride?.ctaText ||
      authModalOverride?.ctaURL
    );
  };

  return (
    showOverrideModal &&
    isNotEmpty(authModalOverride) && (
      <div
        class="modal"
        className={classnames(styles.modalWrapper)}
        id="override-modal-wrapper"
        style={{
          backgroundColor: checkBackgroundColor(
            authModalOverride?.backgroundColor
          ),
        }}
      >
        <div className={classnames(styles.closeButton)}>
          <CloseButton onClick={closeModal} />
        </div>
        <div className={classnames(styles.modalContent)}>
          {authModalOverride?.title && (
            <Text component="h4">{authModalOverride?.title}</Text>
          )}
          {authModalOverride?.body && (
            <div className={classnames(styles.bodyText)}>
              <Text component="p" size="default">
                {authModalOverride?.body}
              </Text>
            </div>
          )}
        </div>
        {authModalOverride?.ctaURL && authModalOverride?.ctaText && (
          <div className={classnames(styles.modalButton)}>
            <SegmentImpression
              eventName="link_viewed"
              eventType={ImpressionType.TRACK}
              eventProps={{
                segmentAttributes: {
                  entity_name: 'global_nav_flexible_modal_cta',
                  destination_url: authModalOverride?.ctaURL,
                  link_copy: authModalOverride?.ctaText,
                  link_position: 0,
                  impression_id: segmentImpressionId,
                },
              }}
            >
              <Button
                primary
                block
                href={addTrkParams({
                  link: authModalOverride?.ctaURL.toString(),
                  additionalParams: {
                    trk_channel: 'web',
                    trk_copy: authModalOverride?.title,
                    trk_element: 'button',
                    trk_format: 'authModalOverride',
                    trk_location: 'global_nav_flexible_modal_cta',
                    trk_nldt: 'undefined',
                  },
                })}
                onClick={() => handleClick(segmentImpressionId)}
              >
                {authModalOverride?.ctaText}
              </Button>
            </SegmentImpression>
          </div>
        )}
      </div>
    )
  );
};

export default OverrideModal;
